<template>
  <div>
    <v-card>
      <v-card-title>
        Aniversariantes do Dia {{ birthdayTitle }}
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!-- filter quicksearch -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              class="user-search me-3 mb-4"
              label="Data de Nascimento"
              hide-details
              readonly
              outlined
              dense
              v-bind="attrs"
              @blur="filterOptions.diaMes = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterOptions.diaMes"
            locale="pt-BR"
            width="270"
            @input="dateMenu = false;"
          ></v-date-picker>
        </v-menu>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="filterBirthdaysDate"
          >
            <v-icon>{{ icons.mdiFilter }}</v-icon>
            <span>Filtrar</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="filterReset"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiFilterRemove }}
            </v-icon>
            <span>Limpar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!--table-->
      <v-data-table
        :headers="headers"
        :items="birthdayList"
        :loading="loading"
        loading-text="Carregando dados..."
        hide-default-footer
        disable-sort
      >
        <!--data empty-->
        <template v-slot:no-data>
          {{ noDataText }}
        </template>
        <!--field phone_cell-->
        <template #[`item.phone`]="{item}">
          <v-icon
            medium
            @click="chatWhatsapp(item.phone)"
          >
            {{ icons.mdiWhatsapp }}
          </v-icon>
          {{ item.phone }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterRemove,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'

export default {
  data: vm => ({
    icons: {
      mdiAccountOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiExportVariant,
      mdiFileDocumentOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiPencilOutline,
      mdiPlus,
      mdiSquareEditOutline,
      mdiWhatsapp,
    },

    /* filter */
    filterOptions: {
      diaMes: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    },
    dateMenu: false,
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    ),

    /* data table */
    birthdayTitle: '',
    birthdayList: [],
    noDataText: 'Nenhum aniversariante encontrado',
    loading: true,
    headers: [
      { text: 'DATA', value: 'data_formatada' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'CONTATO', value: 'phone' },
      { text: 'EMAIL', value: 'email' },
    ],

    /* router */
    routeOptions: {
      resource: 'index',
      method: 'get',
      params: {},
    },
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.filterOptions.diaMes)
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.filterOptions.diaMes)
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.birthdayTitle = this.formatDateDDMM(this.filterOptions.diaMes)
      await axiosIns
        .get('/api/v1/integration/client/birthdays_of_the_day')
        .then(res => {
          this.birthdayList = res.data.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    formatDateDDMM(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}`
    },

    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    async filterBirthdaysDate() {
      this.loading = true
      this.birthdayList = []
      this.routeOptions = {
        resource: 'filter_birthdays_date',
        method: 'post',
        params: {
          dia_mes: this.filterOptions.diaMes,
        },
      }
      this.birthdayTitle = this.formatDateDDMM(this.filterOptions.diaMes)
      await axiosIns
        .post('/api/v1/integration/client/filter_birthdays_date', this.routeOptions.params)
        .then(res => {
          this.birthdayList = res.data.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async filterReset() {
      this.loading = true
      this.birthdayList = []
      this.filterOptions.diaMes = ''
      this.routeOptions = {
        resource: 'index',
        method: 'get',
        params: {},
      }

      this.initialize()
    },

    chatWhatsapp(phone) {
      window.open(`https://wa.me/55${phone}`, '_blank')
    },
  },
}
</script>
